import { Controller } from "@hotwired/stimulus"
import { loadCss } from "../utils/css_loader";

// Connects to data-controller="datetime-picker"
export default class extends Controller {
  static values = { 
    enableTime: Boolean,
    minDate: { type: String, default: 'today' }
  };

  async connect() {
    const flatpickr = await import('flatpickr');
    await loadCss('/flatpickr.min.css');

    let locale;
    if (window.currentLocale === 'zh-HK') {
      const zhTw = await import('flatpickr/dist/l10n/zh-tw.js');
      locale = zhTw.default.MandarinTraditional; // Access the specific locale
    } else if (window.currentLocale === 'zh-CN') {
      const zhCn = await import('flatpickr/dist/l10n/zh.js');
      locale = zhCn.default.Mandarin; // Access the specific locale
    }

    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 3);

    flatpickr.default(this.element, {
      enableTime: this.enableTimeValue,
      dateFormat: this.enableTimeValue ? "Y-m-d H:i" : "Y-m-d",
      time_24hr: true,
      minDate: this.minDateValue,
      maxDate: maxDate,
      locale: locale || undefined
    });

    // this.element.addEventListener('focus', function() {
    //  this.element.blur();
    // });

    this.element.readOnly = false;
  }
}
