import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="autocomplete"
export default class extends Controller {
  static targets = ["input", "suggestions", "error", "phaseGroup", "phaseSelect", "buildingGroup", "buildingSelect", "floorUnit", "searchField", "manualForm"];
  static values = { resourceType: String }

  connect() {
    this.selectedSuggestionIndex = -1;
  }

  search() {
    this.inputTarget.classList.toggle('search-address-invalid', true);
    let query = this.inputTarget.value;

    if (!query.length) {
      this.clearSuggestions();
      return;
    }

    fetch(`/${window.currentLocale}/${this.resourceTypeValue}/autocomplete?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => this.displaySuggestions(data));
  }

  displaySuggestions(data) {
    this.clearSuggestions();
    const linkItem = this.suggestionsTarget.querySelector('.link-item');
    this.suggestionsTarget.classList.remove('d-none');
    data.forEach((item) => {
      let listItem = document.createElement('button');
      listItem.type = 'button';
      listItem.classList.add("list-group-item", "list-group-item-action");
      if (this.resourceTypeValue == 'estates') {
        const addressParts = [item.district, item.street_address].filter(Boolean);
        const addressString = addressParts.join(' &#x2022; ');
        listItem.innerHTML = `
          <strong>${item.name}</strong><br>
          ${addressString}
        `;
      } else {
        listItem.textContent = item;
      }
      listItem.addEventListener('click', () => {
        this.inputTarget.value = item.name;
        this.inputTarget.classList.toggle('search-address-invalid', false);
        if (this.hasErrorTarget) {  // Check if the error target exists
          this.errorTarget.classList.add('d-none'); // Hide the error message
        }
        this.clearSuggestions();
        this.toggleFormElements(this.phaseGroupTarget, false);
        this.toggleFormElements(this.buildingGroupTarget, false);
        this.toggleFormElements(this.floorUnitTarget, false);

        this.fetchInfo("phases", item.id);
      });
      this.suggestionsTarget.insertBefore(listItem, linkItem);
    });
  }

  clearSuggestions() {
    const suggestionItems = this.suggestionsTarget.querySelectorAll('button:not(.link-item)');
    suggestionItems.forEach(item => item.remove());
    this.suggestionsTarget.classList.add('d-none');
  }

  navigate(e) {
    let suggestions = this.suggestionsTarget.querySelectorAll('li');
    if (!suggestions.length) return;

    switch (e.key) {
      case 'ArrowDown':
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex + 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'ArrowUp':
        if (this.selectedSuggestionIndex <= 0) this.selectedSuggestionIndex = suggestions.length;
        this.selectedSuggestionIndex = (this.selectedSuggestionIndex - 1) % suggestions.length;
        this.highlightSuggestion(suggestions);
        break;
      
      case 'Enter':
        if (this.selectedSuggestionIndex > -1) {
          e.preventDefault(); // Prevent form submission
          suggestions[this.selectedSuggestionIndex].click();
        }
        break;
    }
  }

  highlightSuggestion(suggestions) {
    suggestions.forEach((suggestion, index) => {
      suggestion.classList.toggle('selected', index === this.selectedSuggestionIndex);
    });
  }

  hideSuggestions() {
    setTimeout(() => {
      this.clearSuggestions();
    }, 150);
  }

  async fetchInfo(type, estateId, phaseId = null) {
    try {
      const url = new URL(`${window.currentLocale}/${type}/info`, window.location.origin);
      if (estateId) {
        url.searchParams.append('estateId', estateId);
      }
      
      if (phaseId) {
        url.searchParams.append('phaseId', phaseId); // Append phaseId only for buildings
      }

      const response = await fetch(url, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          }
      });

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (type === 'phases') {
        if (data.length > 1) {
          this.populateSelect(this.phaseSelectTarget, data);
        } else {
          this.fetchInfo('buildings', estateId);
        }
      } else {
        this.populateSelect(this.buildingSelectTarget, data, data.length > 1);
        if (data.length === 1) {
          this.buildingSelectTarget.value = data[0].id;
          this.buildingSelectTarget.dispatchEvent(new Event('change'));
        }
      }
      
    } catch (error) {
        console.error(`Error fetching ${type}:`, error);
    }
  }

  populateSelect(selectElement, items, showElement=true) {    
    // Clear existing options
    Array.from(selectElement.options).forEach(option => {
      if (option.value) { // Only remove options that have a value (not the blank option)
        selectElement.remove(option.index);
      }
    });

    // Populate the select with new options
    items.forEach(item => {
      const option = new Option(item.name, item.id);
      selectElement.appendChild(option);
    });

    if (showElement) {
      this.toggleFormElements(selectElement.parentElement, true);
    } else {
      selectElement.parentElement.classList.add('d-none');
    }
  }

  onPhaseChange(event) {
    const selectedPhaseId = event.target.value;
    this.toggleFormElements(this.floorUnitTarget, false);
    if (selectedPhaseId) {
      this.fetchInfo('buildings', null, selectedPhaseId);
    } else {
      this.buildingSelectTarget.parentElement.classList.add('d-none');
    }
  }

  onBuildingChange(event) {
    this.toggleFormElements(this.floorUnitTarget, !!event.target.value);
  }

  showManualForm() {
    this.toggleFormElements(this.searchFieldTarget, false);
    this.toggleFormElements(this.phaseGroupTarget, false);
    this.toggleFormElements(this.buildingGroupTarget, false);
    this.toggleFormElements(this.floorUnitTarget, false);

    this.toggleFormElements(this.manualFormTarget, true);
  }
  
  showSearchForm(event) {
    event.preventDefault();
    this.toggleFormElements(this.searchFieldTarget, true);
  
    this.toggleFormElements(this.manualFormTarget, false);
    this.toggleFormElements(this.floorUnitTarget, false);
  }
  
  toggleFormElements(target, isRequired) {
    target.classList.toggle('d-none', !isRequired);
    const elements = target.querySelectorAll('input, select');
    
    elements.forEach(element => {
      element.required = isRequired;
      
      if (!isRequired) {
        element.value = '';
      }
    });
  }
}
